import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import api from '../helpers/api'
import FormMessages from '../components/FormMessages'
import PasswordResetForm from '../components/PasswordResetForm'
import { dates } from '../constants'

class ZapomenuteHeslo extends React.Component {
    constructor() {
        super();
        this.state = {
            successMessage: '',
            errors: [],
        }

        this.handleReset = this.handleReset.bind(this);
    }

    handleReset(event) {
        event.preventDefault();
        const payload = new FormData(event.target);

        api.post( '/reset-password', payload, ( response ) => {
            const data = response.data;
            if ( 'error' === data.status ) {
                this.setState({
                    errors: data.data,
                    successMessage: '',
                });
            } else {
                this.setState({
                    successMessage: data.data,
                    errors: [],
                })
            }
        } );
    }

    render() {
        let formComponent;

        formComponent = <>
            <header className="major">
                <h2>Zapomenuté heslo</h2>
            </header>
            <PasswordResetForm resetCallback={this.handleReset} />
        </>

        return (
            <Layout>
                <SEO
                title="Zapomenuté heslo"
                description={`Reset hesla k účtu na hře Navíc, která se koná v Praze ${dates.game}.`}
                />

                <div id="main">
                    <section id="one">
                        <div className="inner">
                            <FormMessages successMessage={this.state.successMessage} errors={this.state.errors} />
                            {formComponent}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default ZapomenuteHeslo
